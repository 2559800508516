import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkSize'
})
export class CheckSizePipe implements PipeTransform {

  transform(files: Array<any>, args?: any): any {
    let filesize = 0;
    files.forEach(file => {
      filesize += file.size;
    });

    return !((filesize / 1000000) > 2);
  }

}
