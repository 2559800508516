import {Component, OnInit} from '@angular/core';
import { Event, NavigationCancel, NavigationEnd,
  NavigationError, NavigationStart, Router } from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {EventEmmiterService} from './_services/event-emmiter/event-emmiter.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'lidbeer';
  loading: boolean = false;
  showTckt: boolean = false;
  showCombo: boolean = false;

  constructor(private router: Router, private spinner: NgxSpinnerService, private emitter: EventEmmiterService) {
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          this.spinner.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          setTimeout(() => {
            this.loading = false;
            this.spinner.hide();
          }, 1500);
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  ngOnInit() {
    this.emitter.currentStatusOne.subscribe(status => this.showTckt = status);
    this.emitter.currentStatusTwo.subscribe(status => this.showCombo = status);
  }

}
