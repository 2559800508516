<div style="z-index: 1000; height: 100vh" class="container-fluid m-0 p-0 fixed-top" [ngClass]="{'d-none': !loading}">
<ngx-spinner bdOpacity = 0.9
             bdColor = "#724225"
             size = "medium"
             color = "#fcb329"
             type = "line-scale-pulse-out"
             [fullScreen] = "true">
  <p style="color: white" > Пачакайце.... </p>
</ngx-spinner>
</div>

<div *ngIf="showTckt" style="z-index: 1000; height: 100vh" class="container-fluid m-0 p-0 fixed-top">
  <button (click)="showTckt = false" class="btn rounded-circle"
          style="position: absolute;top: 2%;right: 2%;background: transparent;z-index: 2000;height: 15px;width: 15px;"
          type="button">&times;</button>
  <iframe class="lb_tickets_iframe"  src="https://wd.ticketpro.by/event/eve_7198_15_ru" frameborder="0"></iframe>
</div>
<div *ngIf="showCombo" style="z-index: 1000; height: 100vh" class="container-fluid m-0 p-0 fixed-top">
  <button (click)="showCombo = false" class="btn rounded-circle"
          style="position: absolute;top: 2%;right: 2%;background: transparent;z-index: 2000;height: 15px;width: 15px;"
          type="button">&times;</button>
  <iframe class="lb_tickets_iframe"   src="https://wd.ticketpro.by/event/eve_5814_15_ru" frameborder="0" ></iframe>
</div>

<div class="container-fluid lb-container m-0 p-0">
  <app-lb-navbar></app-lb-navbar>
  <div class="row m-0 p-0">
    <div class="col-12 p-0 lb-router-outlet">
        <router-outlet></router-outlet>
        <app-lb-footer></app-lb-footer>
    </div>
  </div>
</div>



