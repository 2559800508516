import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CollapseDirective} from '../_directives/collapse.directive';
import {LbFooterComponent} from '../lb-footer/lb-footer.component';
import { LbTicketButtonComponent } from '../lb-ticket-button/lb-ticket-button.component';
import { CommonModule } from '@angular/common';
import { ButtonDirective } from '../_directives/button.directive';
import { LbPhoneComponent } from '../lb-phone/lb-phone.component';
import { NgxMaskModule } from 'ngx-mask';
import { LightboxModule } from 'ngx-lightbox';
import { PluralizePipe } from '../_pipes/pluralize.pipe';
import { CustomDatePipe } from '../_pipes/custom-date.pipe';
import { ExtensionPipe } from '../_pipes/extension.pipe';
import { LbPrivacyComponent } from '../lb-privacy/lb-privacy.component';
import { LbRequireInfoComponent } from '../lb-require-info/lb-require-info.component';
import { LbVideoComponent } from '../lb-video/lb-video.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {NgxPaginationModule} from 'ngx-pagination';
import {FilesPipe} from '../_pipes/files.pipe';
import { LbChooseComponent } from '../lb-choose/lb-choose.component';
import { CheckSizePipe } from '../_pipes/check-size.pipe';


@NgModule({
  declarations: [
    CollapseDirective,
    ButtonDirective,
    LbFooterComponent,
    LbTicketButtonComponent,
    LbPhoneComponent,
    PluralizePipe,
    CustomDatePipe,
    ExtensionPipe,
    FilesPipe,
    CheckSizePipe,
    LbPrivacyComponent,
    LbRequireInfoComponent,
    LbVideoComponent,
    LbChooseComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    LightboxModule,
    NgxSpinnerModule,
    NgxPaginationModule
  ],
  providers: [
    PluralizePipe,
    CustomDatePipe,
    ExtensionPipe,
    FilesPipe,
    CheckSizePipe
  ],
  exports: [
    CollapseDirective,
    ButtonDirective,
    LbFooterComponent,
    LbTicketButtonComponent,
    LbPhoneComponent,
    LbPrivacyComponent,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    LightboxModule,
    LbRequireInfoComponent,
    CustomDatePipe,
    ExtensionPipe,
    FilesPipe,
    CheckSizePipe,
    LbVideoComponent,
    NgxSpinnerModule,
    NgxPaginationModule,
    LbChooseComponent
  ]
})
export class LbSharedModule { }
