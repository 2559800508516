import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const array = value.split('.');
    if (parseInt(array[0], 10) > 31 || parseInt(array[1], 10) > 12 || value === '') {
      return false;
    }
    return super.transform(array[1] + '-' + array[0] + '-' + array[2], args);
  }

}
