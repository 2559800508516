import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: './lb-main/lb-main.module#LbMainModule'
  },
  {
    path: 'gala-concert',
    loadChildren: './lb-gala-concert/lb-gala-concert.module#LbGalaConcertModule'
  },
  {
    path: 'gala-concert/:id',
    loadChildren: './lb-gala-concert-detail/lb-gala-concert-detail.module#LbGalaConcertDetailModule'
  },
  {
    path: 'about',
    loadChildren: './lb-about/lb-about.module#LbAboutModule'
  },
  {
    path: 'map',
    loadChildren: './lb-map/lb-map.module#LbMapModule'
  },
  {
    path: 'faq',
    loadChildren: './lb-faq/lb-faq.module#LbFaqModule'
  },  
  {
    path: 'program',
    loadChildren: './lb-program/lb-program.module#LbProgramModule'
  },  
  {
    path: 'contacts',
    loadChildren: './lb-contacts/lb-contacts.module#LbContactsModule'
  },
  {
    path: 'contacts/message',
    loadChildren: './lb-contacts/lb-contacts-feedback/lb-contacts-feedback.module#LbContactsFeedbackModule'
  },
  {
    path: 'contacts/partner',
    loadChildren: './lb-contacts/lb-contacts-partner/lb-contacts-partner.module#LbContactsPartnerModule'
  },
  {
    path: 'sway',
    loadChildren: './lb-sway/lb-sway.module#LbSwayModule'
  },
  // {
  //   path: 'sway/craft',
  //   loadChildren: './lb-contacts/lb-contacts-craft/lb-contacts-craft.module#LbContactsCraftModule'
  // },
  // {
  //   path: 'sway/game',
  //   loadChildren: './lb-contacts/lb-contacts-game/lb-contacts-game.module#LbContactsGameModule'
  // },
  // {
  //   path: 'sway/foodcort',
  //   loadChildren: './lb-contacts/lb-contacts-foodcort/lb-contacts-foodcort.module#LbContactsFoodcortModule'
  // },
  // {
  //   path: 'sway/free-scene',
  //   loadChildren: './lb-contacts/lb-contacts-free-scene/lb-contacts-free-scene.module#LbContactsFreeSceneModule'
  // },
  //{
  //  path: 'contacts/volunteer',
  //  loadChildren: './lb-contacts/lb-volunteer/lb-volunteer.module#LbVolunteerModule'
  //},
  {
    path: 'success',
    loadChildren: './lb-contacts/lb-contacts-feedback-success/lb-contacts-feedback-success.module#LbContactsFeedbackSuccessModule'
  },
  {
    path: 'rules',
    loadChildren: './lb-rules/lb-rules.module#LbRulesModule'
  },
  {
    path: 'freescene-rules',
    loadChildren: './lb-freescene-rules/lb-freescene-rules.module#LbFreesceneRulesModule'
  },
  {
    path: 'visitors-rules',
    loadChildren: './lb-people-rules/lb-people-rules.module#LbPeopleRulesModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
