import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extension'
})
export class ExtensionPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value.split('.')[1] === 'jpg' || value.split('.') === 'jpeg') {
      return true;
    } else {
      return false;
    }
  }

}
