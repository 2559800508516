import { Pipe, PipeTransform } from '@angular/core';
import {ExtensionPipe} from './extension.pipe';

@Pipe({
  name: 'files'
})
export class FilesPipe extends ExtensionPipe implements PipeTransform {

  transform(value: string, type?: string): any {
    let docs: Array<any> = [];
    let images: Array<any> = [];
    if (super.transform(value)) {
      images.push(value);
    } else {
      docs.push(value);
    }

    switch (type) {
      case 'docs':
        return docs;
      case 'img':
        return images;
    }
  }

}
