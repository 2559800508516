import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmmiterService {
  private messageStatusOne = new BehaviorSubject(false);
  private messageStatusTwo = new BehaviorSubject(false);
  currentStatusOne = this.messageStatusOne.asObservable();
  currentStatusTwo = this.messageStatusTwo.asObservable();
  constructor() { }

  showTickets(status: boolean) {
    this.messageStatusOne.next(status);
  }

  showCombo(status: boolean) {
    this.messageStatusTwo.next(status);
  }
}
