<div class="container">
  <div class="row m-0 p-0">
    <nav class="navbar navbar-expand-xl col-xl-12 mx-xl-auto col-exl-10 mx-exl-auto lb-navbar">
      <a class="navbar-brand" routerLink="">
        <img [src]="logo" alt="" class="">
      </a>

      <button  class="navbar-toggler hamburger hamburger--collapse" [ngClass]="{'is-active' : menu.collapse}" type="button" (click)="collapse()">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
      </button>
      <div class="collapse navbar-collapse" [ngClass]="{'show' : menu.collapse}">
        <ul class="navbar-nav d-flex justify-content-between ml-xl-auto w-100">
          <li *ngFor="let item of menu.menu" class="nav-item">
            <a class="nav-link" [routerLink]="item.link" routerLinkActive="active-route" (click)="collapse()">{{ item.title }}</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="http://participants.lidbeer.by" routerLinkActive="active-route" (click)="collapse()">Удзельнікі</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="'program'" routerLinkActive="active-route" href="/program" routerLinkActive="active-route" (click)="collapse()">Праграма</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="'map'" routerLinkActive="active-route" href="/map" routerLinkActive="active-route" (click)="collapse()">Карта</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://shukai.lidbeer.by/" routerLinkActive="active-route" (click)="collapse()">Шукай LIDBEER</a>
          </li>
          
          <li class="nav-item">
              <app-lb-ticket-button (click)="showCommboIframe()"
                                    [text]="'набыць квiткi'" id="dropdownMenuButton"
                                    [type]="'button'"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></app-lb-ticket-button>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</div>
