import { Pipe, PipeTransform } from '@angular/core';
import {Router} from '@angular/router';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {
  constructor(private router: Router) {}
  transform(value: any, args?: any): any {
    const index = this.router.url.indexOf('/admin');
    if (value.length == 1) {
      return value[0].name;
    }
    if (value.length > 1 && value.length < 5) {
      if (index > -1) {
        return `Будет загружено ${value.length} файла`;
      } else {
        return `Будзе запампавана ${value.length} файла`;
      }
    }
    if (value.length >= 5 || value.length % 10 > 4  && value.length < 20) {

      if (index > -1) {
        return `Будет загружено ${value.length} файлов`;
      } else {
        return `Будзе запампавана ${value.length} файлаў`;
      }
    }
    if (value.length % 10 <= 4 && value.length >= 20) {
      if (index > -1) {
        return `Будет загружено ${value.length} файлов`;
      } else {
        return `Будзе запампавана ${value.length} файла`;
      }
    }
    return null;
  }

}
