import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Response } from '../../_interfaces/response';
import {Artist} from '../../_interfaces/artist';

@Injectable()
export class HttpService {
  private base = environment.production ? `${environment.protocol}://${environment.host}/${environment.prefix}` : `${environment.protocol}://${environment.host}:${environment.port}/${environment.prefix}`;
  constructor(private http: HttpClient) {}

  feedbackMessage(data: any) {
    return this.http.post<Response>(`${this.base}/feedback/message/send`, data);
  }

  feedbackPartner(data: any) {
    return this.http.post<Response>(`${this.base}/feedback/partners/send`, data);
  }

  feedbackVolunteer(data: any) {
    return this.http.post<Response>(`${this.base}/feedback/volunteer/send`, data);
  }

  requestFreescene(data: any) {
    return this.http.post<Response>(`${this.base}/request/freescene`, data);
  }

  requestIndividualGame(data: any) {
    return this.http.post<Response>(`${this.base}/request/individual-game`, data);
  }

  requestEntityGame(data: any) {
    return this.http.post<Response>(`${this.base}/request/entity-game`, data);
  }

  requestIndividualCraft(data: any) {
    return this.http.post<Response>(`${this.base}/request/individual-craft`, data);
  }

  requestEntityCraft(data: any) {
    return this.http.post<Response>(`${this.base}/request/entity-craft`, data);
  }

  foodcort(data) {
    return this.http.post(`${this.base}/request/foodcort`, data);
  }

  artistRetrieve() {
    return this.http.get<Artist>(`${this.base}/v1/artist`);
  }

  artist(id) {
    return this.http.get(`${this.base}/v1/artist/${id}`);
  }

}
